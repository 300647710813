import Vue from "vue";
import VueRouter from "vue-router";
// 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

// 常量路由 所有角色可见
export const constantRoutes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
  {
    path: "*",
    name: "dashboard",
    component: () => import("@/views/dashboard"),
  },
];

// 异步路由 根据角色过滤可见
export const asyncRoutes = [
  //首页
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home"),
    meta: [1, 2, 3],
  },
  //个人中心
  {
    path: "/center",
    name: "center",
    component: () => import("@/views/center"),
    meta: [1, 2, 3],
  },

  // 门店管理
  //#region
  // 门店列表
  {
    path: "/store/list",
    name: "storeList",
    component: () => import("@/views/store/list"),
    meta: [1, 2],
  },
  // 门店新增
  {
    path: "/store/list/add",
    name: "storeListAdd",
    component: () => import("@/views/store/list/add"),
    meta: [1, 2],
  },
  // 门店修改
  {
    path: "/store/list/update",
    name: "storeListUpdate",
    component: () => import("@/views/store/list/update"),
    meta: [1, 2],
  },
  // 门店详情
  {
    path: "/store/list/detail",
    name: "storeListDetail",
    component: () => import("@/views/store/list/detail"),
    meta: [1, 2, 3],
  },
  // 门店趋势
  {
    path: "/store/list/tendency",
    name: "storeListTendency",
    component: () => import("@/views/store/list/tendency"),
    meta: [1, 2],
  },
  // 门店员工
  {
    path: "/store/staff",
    name: "storeStaff",
    component: () => import("@/views/store/staff"),
    meta: [1, 2, 3],
  },
  // 员工新增
  {
    path: "/store/staff/add",
    name: "storeStaffAdd",
    component: () => import("@/views/store/staff/add"),
    meta: [3],
  },
  // 员工修改
  {
    path: "/store/staff/update",
    name: "storeStaffUpdate",
    component: () => import("@/views/store/staff/update"),
    meta: [3],
  },
  // 员工详情
  {
    path: "/store/staff/detail",
    name: "storeStaffDetail",
    component: () => import("@/views/store/staff/detail"),
    meta: [1, 2, 3],
  },
  // 门店员工等级
  {
    path: "/store/staffLevel",
    name: "storeStaffLevel",
    component: () => import("@/views/store/staffLevel"),
    meta: [1, 2],
  },
  //#endregion

  // 平台管理
  //#region
  // 横幅管理
  {
    path: "/platform/banner",
    name: "platformBanner",
    component: () => import("@/views/platform/banner"),
    meta: [1, 2],
  },
  // 搜索词管理
  {
    path: "/platform/search",
    name: "platformSearch",
    component: () => import("@/views/platform/search"),
    meta: [1, 2],
  },
  // 常见问题
  {
    path: "/platform/issue",
    name: "platformIssue",
    component: () => import("@/views/platform/issue"),
    meta: [1, 2],
  },
  // 常见问题新增
  {
    path: "/platform/issue/add",
    name: "platformIssueAdd",
    component: () => import("@/views/platform/issue/add"),
    meta: [1, 2],
  },
  // 常见问题修改
  {
    path: "/platform/issue/update",
    name: "platformIssueUpdate",
    component: () => import("@/views/platform/issue/update"),
    meta: [1, 2],
  },
  // 常见问题详情
  {
    path: "/platform/issue/detail",
    name: "platformIssueDetail",
    component: () => import("@/views/platform/issue/detail"),
    meta: [1, 2],
  },
  //#endregion

  // 物流管理
  {
    path: "/platform/logistics",
    name: "platformLogistics",
    component: () => import("@/views/platform/logistics"),
    meta: [1, 2],
  },

  // 月经知识
  //#region
  // 月经常识
  {
    path: "/learning/nous",
    name: "learningNous",
    component: () => import("@/views/learning/nous"),
    meta: [1, 2],
  },
  // 试卷类型
  {
    path: "/learning/paperType",
    name: "learningPaperType",
    component: () => import("@/views/learning/paperType"),
    meta: [1, 2],
  },
  // 试卷列表
  {
    path: "/learning/paper",
    name: "learningPaper",
    component: () => import("@/views/learning/paper"),
    meta: [1, 2],
  },
  // 试卷新增
  {
    path: "/learning/paper/add",
    name: "learningPaperAdd",
    component: () => import("@/views/learning/paper/add"),
    meta: [1, 2],
  },
  // 试卷修改
  {
    path: "/learning/paper/update",
    name: "learningPaperUpdate",
    component: () => import("@/views/learning/paper/update"),
    meta: [1, 2],
  },
  // 题目列表
  {
    path: "/learning/topic",
    name: "learningTopic",
    component: () => import("@/views/learning/topic"),
    meta: [1, 2],
  },
  // 题目修改
  {
    path: "/learning/topic/update",
    name: "learningTopicUpdate",
    component: () => import("@/views/learning/topic/update"),
    meta: [1, 2],
  },
  // 题目新增
  {
    path: "/learning/topic/add",
    name: "learningTopicAdd",
    component: () => import("@/views/learning/topic/add"),
    meta: [1, 2],
  },
  // 题目详情
  {
    path: "/learning/topic/detail",
    name: "learningTopicDetail",
    component: () => import("@/views/learning/topic/detail"),
    meta: [1, 2],
  },
  // 结果建议
  {
    path: "/learning/result",
    name: "learningResult",
    component: () => import("@/views/learning/result"),
    meta: [1, 2],
  },
  // 结果建议修改
  {
    path: "/learning/result/detail",
    name: "learningResultDetail",
    component: () => import("@/views/learning/result/detail"),
    meta: [1, 2],
  },
  //#endregion

  // 会员管理
  //#region
  // 会员统计
  {
    path: "/member/statistics",
    name: "memberStatistics",
    component: () => import("@/views/member/statistics"),
    meta: [1, 2],
  },
  // 会员统计趋势
  {
    path: "/member/statistics/tendency",
    name: "memberStatisticsTendency",
    component: () => import("@/views/member/statistics/tendency"),
    meta: [1, 2],
  },
  // 会员来源统计
  {
    path: "/member/sourceStatistics",
    name: "memberSourceStatistics",
    component: () => import("@/views/member/sourceStatistics"),
    meta: [1, 2],
  },
  // 会员来源统计趋势
  {
    path: "/member/sourceStatistics/tendency",
    name: "memberSourceStatisticsTendency",
    component: () => import("@/views/member/sourceStatistics/tendency"),
    meta: [1, 2],
  },
  // 会员行为统计
  {
    path: "/member/behaviorStatistics",
    name: "memberBehaviorStatistics",
    component: () => import("@/views/member/behaviorStatistics"),
    meta: [1, 2],
  },
  // 会员行为统计趋势
  {
    path: "/member/behaviorStatistics/tendency",
    name: "memberBehaviorStatisticsTendency",
    component: () => import("@/views/member/behaviorStatistics/tendency"),
    meta: [1, 2],
  },
  // 会员行为统计详情
  {
    path: "/member/behaviorStatistics/detail",
    name: "memberBehaviorStatisticsDetail",
    component: () => import("@/views/member/behaviorStatistics/detail"),
    meta: [1, 2],
  },
  // 会员信息
  {
    path: "/member/info",
    name: "memberInfo",
    component: () => import("@/views/member/info"),
    meta: [1, 2],
  },
  // 会员信息详情
  {
    path: "/member/info/detail",
    name: "memberInfoDetail",
    component: () => import("@/views/member/info/detail"),
    meta: [1, 2],
  },
  // 会员资金统计
  {
    path: "/member/fund",
    name: "memberFund",
    component: () => import("@/views/member/fund"),
    meta: [1, 2],
  },
  // 会员资金统计趋势
  {
    path: "/member/fund/tendency",
    name: "memberFundTendency",
    component: () => import("@/views/member/fund/tendency"),
    meta: [1, 2],
  },
  // 会员账户信息
  {
    path: "/member/account",
    name: "memberAccount",
    component: () => import("@/views/member/account"),
    meta: [1, 2],
  },
  // 会员账户信息详情
  {
    path: "/member/account/detail",
    name: "memberAccountDetail",
    component: () => import("@/views/member/account/detail"),
    meta: [1, 2],
  },
  // 会员积分
  {
    path: "/member/integral",
    name: "memberIntegral",
    component: () => import("@/views/member/integral"),
    meta: [1, 2],
  },
  // 会员积分明细
  {
    path: "/member/integral/detail",
    name: "memberIntegralDetail",
    component: () => import("@/views/member/integral/detail"),
    meta: [1, 2],
  },
  // 会员报告
  {
    path: "/member/review",
    name: "memberReview",
    component: () => import("@/views/member/review"),
    meta: [1, 2],
  },
  // 会员报告详情
  {
    path: "/member/review/detail",
    name: "memberReviewDetail",
    component: () => import("@/views/member/review/detail"),
    meta: [1, 2],
  },
  // 会员报告名称
  {
    path: "/member/review/name",
    name: "memberReviewName",
    component: () => import("@/views/member/review/name"),
    meta: [1, 2],
  },
  //#endregion

  // 耗材管理(运营)
  //#region
  // 耗材信息
  {
    path: "/consumeO/info",
    name: "consumeOInfo",
    component: () => import("@/views/consumeO/info"),
    meta: [1, 2],
  },
  // 耗材信息新增
  {
    path: "/consumeO/info/add",
    name: "consumeOInfoAdd",
    component: () => import("@/views/consumeO/info/add"),
    meta: [1, 2],
  },
  // 耗材信息修改
  {
    path: "/consumeO/info/update",
    name: "consumeOInfoUpdate",
    component: () => import("@/views/consumeO/info/update"),
    meta: [1, 2],
  },
  // 耗材信息详情
  {
    path: "/consumeO/info/detail",
    name: "consumeOInfoDetail",
    component: () => import("@/views/consumeO/info/detail"),
    meta: [1, 2],
  },
  // 门店耗材
  {
    path: "/consumeO/store",
    name: "consumeOStore",
    component: () => import("@/views/consumeO/store"),
    meta: [1, 2],
  },
  // 耗材详情
  {
    path: "/consumeO/store/csmDetail",
    name: "consumeOStoreCsmDetail",
    component: () => import("@/views/consumeO/store/csmDetail"),
    meta: [1, 2],
  },
  // 耗材趋势
  {
    path: "/consumeO/store/csmTrend",
    name: "consumeOStoreCsmTrend",
    component: () => import("@/views/consumeO/store/csmTrend"),
    meta: [1, 2],
  },
  // 仪器详情
  {
    path: "/consumeO/store/itmDetail",
    name: "consumeOStoreItmDetail",
    component: () => import("@/views/consumeO/store/itmDetail"),
    meta: [1, 2],
  },
  // 仪器趋势
  {
    path: "/consumeO/store/itmTrend",
    name: "consumeOStoreItmTrend",
    component: () => import("@/views/consumeO/store/itmTrend"),
    meta: [1, 2],
  },
  // 耗材订单
  {
    path: "/consumeO/order",
    name: "consumeOOrder",
    component: () => import("@/views/consumeO/order"),
    meta: [1, 2],
  },
  // 耗材订单详情
  {
    path: "/consumeO/order/detail",
    name: "consumeOOrderDetail",
    component: () => import("@/views/consumeO/order/detail"),
    meta: [1, 2],
  },
  // 耗材报损
  {
    path: "/consumeO/frmLoss",
    name: "consumeOFrmLoss",
    component: () => import("@/views/consumeO/frmLoss"),
    meta: [1, 2],
  },
  // 耗材报损详情
  {
    path: "/consumeO/frmLoss/detail",
    name: "consumeOFrmLossDetail",
    component: () => import("@/views/consumeO/frmLoss/detail"),
    meta: [1, 2],
  },
  // 仪器维修
  {
    path: "/consumeO/upkeep",
    name: "consumeOUpkeep",
    component: () => import("@/views/consumeO/upkeep"),
    meta: [1, 2],
  },
  // 仪器维修详情
  {
    path: "/consumeO/upkeep/detail",
    name: "consumeOUpkeepDetail",
    component: () => import("@/views/consumeO/upkeep/detail"),
    meta: [1, 2],
  },
  //#endregion

  // 耗材管理(门店)
  //#region
  // 耗材信息
  {
    path: "/consumeS/info",
    name: "consumeSInfo",
    component: () => import("@/views/consumeS/info"),
    meta: [1, 3],
  },
  // 耗材信息详情
  {
    path: "/consumeS/info/detail",
    name: "consumeSInfoDetail",
    component: () => import("@/views/consumeS/info/detail"),
    meta: [1, 3],
  },
  // 门店仪器
  {
    path: "/consumeS/store",
    name: "consumeSStore",
    component: () => import("@/views/consumeS/store"),
    meta: [1, 3],
  },
  // 门店仪器
  {
    path: "/consumeS/store/detail",
    name: "consumeSStoreDetail",
    component: () => import("@/views/consumeS/store/detail"),
    meta: [1, 3],
  },
  // 耗材订单
  {
    path: "/consumeS/order",
    name: "consumeSOrder",
    component: () => import("@/views/consumeS/order"),
    meta: [1, 3],
  },
  // 耗材订单详情
  {
    path: "/consumeS/order/detail",
    name: "consumeSOrderDetail",
    component: () => import("@/views/consumeS/order/detail"),
    meta: [1, 3],
  },
  // 耗材申请
  {
    path: "/consumeS/order/add",
    name: "consumeSOrderAdd",
    component: () => import("@/views/consumeS/order/add"),
    meta: [1, 3],
  },
  // 耗材报损
  {
    path: "/consumeS/frmLoss",
    name: "consumeSFrmLoss",
    component: () => import("@/views/consumeS/frmLoss"),
    meta: [1, 3],
  },
  // 耗材报损详情
  {
    path: "/consumeS/frmLoss/detail",
    name: "consumeSFrmLossDetail",
    component: () => import("@/views/consumeS/frmLoss/detail"),
    meta: [1, 3],
  },
  // 仪器维修
  {
    path: "/consumeS/upkeep",
    name: "consumeSUpkeep",
    component: () => import("@/views/consumeS/upkeep"),
    meta: [1, 3],
  },
  // 仪器维修详情
  {
    path: "/consumeS/upkeep/detail",
    name: "consumeSUpkeepDetail",
    component: () => import("@/views/consumeS/upkeep/detail"),
    meta: [1, 3],
  },
  //#endregion

  // 商品管理
  //#region
  // 商品分类
  {
    path: "/goods/type",
    name: "goodsType",
    component: () => import("@/views/goods/type"),
    meta: [1, 2],
  },
  // 商品信息
  {
    path: "/goods/info",
    name: "goodsInfo",
    component: () => import("@/views/goods/info"),
    meta: [1, 2],
  },
  // 商品信息商品新增
  {
    path: "/goods/info/add",
    name: "goodsInfoAdd",
    component: () => import("@/views/goods/info/add"),
    meta: [1, 2],
  },
  // 商品信息商品详情
  {
    path: "/goods/info/detail",
    name: "goodsInfoDetail",
    component: () => import("@/views/goods/info/detail"),
    meta: [1, 2],
  },
  // 商品信息商品详情
  {
    path: "/goods/info/update",
    name: "goodsInfoUpdate",
    component: () => import("@/views/goods/info/update"),
    meta: [1, 2],
  },
  // 商品销售
  {
    path: "/goods/sale",
    name: "goodsSale",
    component: () => import("@/views/goods/sale"),
    meta: [1, 2],
  },
  // 商品销售销售新增
  {
    path: "/goods/sale/add",
    name: "goodsSaleAdd",
    component: () => import("@/views/goods/sale/add"),
    meta: [1, 2],
  },
  // 商品销售销售详情
  {
    path: "/goods/sale/detail",
    name: "goodsSaleDetail",
    component: () => import("@/views/goods/sale/detail"),
    meta: [1, 2],
  },
  // 商品销售销售修改
  {
    path: "/goods/sale/update",
    name: "goodsSaleUpdate",
    component: () => import("@/views/goods/sale/update"),
    meta: [1, 2],
  },
  // 培训章节
  {
    path: "/goods/chapter",
    name: "goodsChapter",
    component: () => import("@/views/goods/chapter"),
    meta: [1, 2],
  },
  // 培训课程
  {
    path: "/goods/course",
    name: "goodsCourse",
    component: () => import("@/views/goods/course"),
    meta: [1, 2],
  },
  // 商品订单
  {
    path: "/goods/order",
    name: "goodsOrder",
    component: () => import("@/views/goods/order"),
    meta: [1, 2],
  },
  // 商品订单详情
  {
    path: "/goods/order/detail",
    name: "goodsOrderDetail",
    component: () => import("@/views/goods/order/detail"),
    meta: [1, 2],
  },
  // 商品评价
  {
    path: "/goods/eval",
    name: "goodsEval",
    component: () => import("@/views/goods/eval"),
    meta: [1, 2],
  },
  //#endregion

  // 项目管理
  //#region
  // 项目列表
  {
    path: "/project/list",
    name: "projectList",
    component: () => import("@/views/project/list"),
    meta: [1, 2],
  },
  // 项目新增
  {
    path: "/project/list/add",
    name: "projectListAdd",
    component: () => import("@/views/project/list/add"),
    meta: [1, 2],
  },
  // 项目修改
  {
    path: "/project/list/update",
    name: "projectListUpdate",
    component: () => import("@/views/project/list/update"),
    meta: [1, 2],
  },
  // 项目详情
  {
    path: "/project/list/detail",
    name: "projectListDetail",
    component: () => import("@/views/project/list/detail"),
    meta: [1, 2],
  },
  // 疗程列表
  {
    path: "/project/meals",
    name: "projectMeals",
    component: () => import("@/views/project/meals"),
    meta: [1, 2],
  },
  // 疗程新增
  {
    path: "/project/meals/add",
    name: "projectMealsAdd",
    component: () => import("@/views/project/meals/add"),
    meta: [1, 2],
  },
  // 疗程修改
  {
    path: "/project/meals/update",
    name: "projectMealsUpdate",
    component: () => import("@/views/project/meals/update"),
    meta: [1, 2],
  },
  // 疗程修改
  {
    path: "/project/meals/detail",
    name: "projectMealsDetail",
    component: () => import("@/views/project/meals/detail"),
    meta: [1, 2],
  },
  // 服务列表
  {
    path: "/project/servers",
    name: "projectServers",
    component: () => import("@/views/project/servers"),
    meta: [1, 2],
  },
  // 服务列表详情
  {
    path: "/project/servers/detail",
    name: "projectServersDetail",
    component: () => import("@/views/project/servers/detail"),
    meta: [1, 2],
  },
  // 服务订单
  {
    path: "/project/order",
    name: "projectOrder",
    component: () => import("@/views/project/order"),
    meta: [1, 2],
  },
  // 服务订单详情
  {
    path: "/project/order/detail",
    name: "projectOrderDetail",
    component: () => import("@/views/project/order/detail"),
    meta: [1, 2],
  },
  // 服务评价
  {
    path: "/project/eval",
    name: "projectEval",
    component: () => import("@/views/project/eval"),
    meta: [1, 2],
  },
  // 服务评价列表
  {
    path: "/project/eval/list",
    name: "projectEvalList",
    component: () => import("@/views/project/eval/list"),
    meta: [1, 2],
  },
  // 服务评价详情
  {
    path: "/project/eval/detail",
    name: "projectEvalDetail",
    component: () => import("@/views/project/eval/detail"),
    meta: [1, 2],
  },
  //#endregion

  // 外卖管理
  //#region
  // 外卖分类
  {
    path: "/takeout/type",
    name: "takeoutType",
    component: () => import("@/views/takeout/type"),
    meta: [1, 2],
  },
  // 外卖信息
  {
    path: "/takeout/info",
    name: "takeoutInfo",
    component: () => import("@/views/takeout/info"),
    meta: [1, 2],
  },
  // 外卖信息外卖新增
  {
    path: "/takeout/info/add",
    name: "takeoutInfoAdd",
    component: () => import("@/views/takeout/info/add"),
    meta: [1, 2],
  },
  // 外卖信息外卖详情
  {
    path: "/takeout/info/detail",
    name: "takeoutInfoDetail",
    component: () => import("@/views/takeout/info/detail"),
    meta: [1, 2],
  },
  // 外卖信息外卖详情
  {
    path: "/takeout/info/update",
    name: "takeoutInfoUpdate",
    component: () => import("@/views/takeout/info/update"),
    meta: [1, 2],
  },
  // 外卖销售
  {
    path: "/takeout/sale",
    name: "takeoutSale",
    component: () => import("@/views/takeout/sale"),
    meta: [1, 2],
  },
  // 外卖销售销售新增
  {
    path: "/takeout/sale/add",
    name: "takeoutSaleAdd",
    component: () => import("@/views/takeout/sale/add"),
    meta: [1, 2],
  },
  // 外卖销售销售详情
  {
    path: "/takeout/sale/detail",
    name: "takeoutSaleDetail",
    component: () => import("@/views/takeout/sale/detail"),
    meta: [1, 2],
  },
  // 外卖销售销售修改
  {
    path: "/takeout/sale/update",
    name: "takeoutSaleUpdate",
    component: () => import("@/views/takeout/sale/update"),
    meta: [1, 2],
  },
  // 外卖订单
  {
    path: "/takeout/order",
    name: "takeoutOrder",
    component: () => import("@/views/takeout/order"),
    meta: [1, 2],
  },
  // 外卖订单详情
  {
    path: "/takeout/order/detail",
    name: "takeoutOrderDetail",
    component: () => import("@/views/takeout/order/detail"),
    meta: [1, 2],
  },
  //#endregion

  // 卡项管理
  //#region
  // 卡项类型
  {
    path: "/card/type",
    name: "cardType",
    component: () => import("@/views/card/type"),
    meta: [1, 2],
  },
  // 卡项设置
  {
    path: "/card/set",
    name: "cardSet",
    component: () => import("@/views/card/set"),
    meta: [1, 2],
  },
  // 卡项新增
  {
    path: "/card/set/add",
    name: "cardSetAdd",
    component: () => import("@/views/card/set/add"),
    meta: [1, 2],
  },
  // 卡项修改
  {
    path: "/card/set/update",
    name: "cardSetUpdate",
    component: () => import("@/views/card/set/update"),
    meta: [1, 2],
  },
  // 卡项详情
  {
    path: "/card/set/detail",
    name: "cardSetDetail",
    component: () => import("@/views/card/set/detail"),
    meta: [1, 2],
  },
  // 卡项订单
  {
    path: "/card/order",
    name: "cardOrder",
    component: () => import("@/views/card/order"),
    meta: [1, 2],
  },
  // 卡项订单详情
  {
    path: "/card/order/detail",
    name: "cardOrderDetail",
    component: () => import("@/views/card/order/detail"),
    meta: [1, 2],
  },
  // 卡项服务
  {
    path: "/card/servers",
    name: "cardServer",
    component: () => import("@/views/card/servers"),
    meta: [1, 2],
  },
  // 卡项服务
  {
    path: "/card/servers/detail",
    name: "cardServerDetail",
    component: () => import("@/views/card/servers/detail"),
    meta: [1, 2],
  },
  //#endregion
];

let oldRouter = [
  // 健康师入驻审核列表
  {
    path: "/health/join",
    name: "healthJoin",
    component: () => import("@/views/health/join/index"),
  },
  // 健康师入驻审核修改
  {
    path: "/health/join/update",
    name: "joinUpdate",
    component: () => import("@/views/health/join/update"),
  },
  // 健康师资料审核列表
  {
    path: "/health/aptitude",
    name: "healthAptitude",
    component: () => import("@/views/health/aptitude/index"),
  },
  // 健康师资料审核修改
  {
    path: "/health/aptitude/update",
    name: "healthAptitudeUpdate",
    component: () => import("@/views/health/aptitude/update"),
  },
  // 健康师资料审核详情
  {
    path: "/health/aptitude/detail",
    name: "aptitudeDetail",
    component: () => import("@/views/health/aptitude/detail"),
  },
  // 健康师信息管理列表
  {
    path: "/health/intel",
    name: "healthIntel",
    component: () => import("@/views/health/intel/index"),
  },
  // 健康师信息管理新增入驻
  {
    path: "/health/intel/addJoin",
    name: "intelAddJoin",
    component: () => import("@/views/health/intel/addJoin"),
  },
  // 健康师信息管理入驻详情
  {
    path: "/health/intel/detailJoin",
    name: "healthIntelDetailJoin",
    component: () => import("@/views/health/intel/detailJoin"),
  },
  // 健康师信息管理修改入驻
  {
    path: "/health/intel/updateJoin",
    name: "healthIntelUpdateJoin",
    component: () => import("@/views/health/intel/updateJoin"),
  },
  // 健康师信息管理新增资料
  {
    path: "/health/intel/addIntel",
    name: "healthIntelAddIntel",
    component: () => import("@/views/health/intel/addIntel"),
  },
  // 健康师信息管理资料详情
  {
    path: "/health/intel/detailIntel",
    name: "healthIntelDetailIntel",
    component: () => import("@/views/health/intel/detailIntel"),
  },
  // 健康师信息管理资料详情
  {
    path: "/health/intel/updateIntel",
    name: "healthIntelUpdateIntel",
    component: () => import("@/views/health/intel/updateIntel"),
  },
  // 门店管理入驻审核列表
  {
    path: "/store/join",
    name: "storeJoin",
    component: () => import("@/views/store/join/index"),
    meta: [1, 2],
  },
  // 门店管理入驻审核修改
  {
    path: "/store/join/update",
    name: "storeJoinUpdate",
    component: () => import("@/views/store/join/update"),
    meta: [1, 2],
  },
  // 门店管理门店审核列表
  {
    path: "/store/assess",
    name: "storeAssess",
    component: () => import("@/views/store/assess/index"),
    meta: [1, 2],
  },
  // 门店管理入驻审核详情
  {
    path: "/store/assess/detail",
    name: "storeAssessDetail",
    component: () => import("@/views/store/assess/detail"),
    meta: [1, 2],
  },
  // 门店管理入驻审核修改
  {
    path: "/store/assess/update",
    name: "storeAssessUpdate",
    component: () => import("@/views/store/assess/update"),
    meta: [1, 2],
  },
  // 门店管理资料审核列表
  {
    path: "/store/aptitude",
    name: "storeAptitude",
    component: () => import("@/views/store/aptitude/index"),
    meta: [1, 2],
  },
  // 门店管理资料审核详情
  {
    path: "/store/aptitude/detail",
    name: "storeAptitudeDetail",
    component: () => import("@/views/store/aptitude/detail"),
    meta: [1, 2],
  },
  // 门店管理资料审核修改
  {
    path: "/store/aptitude/update",
    name: "storeAptitudeUpdate",
    component: () => import("@/views/store/aptitude/update"),
    meta: [1, 2],
  },
  // 门店管理门店老板列表
  {
    path: "/store/boss",
    name: "storeBoss",
    component: () => import("@/views/store/boss/index"),
    meta: [1, 2],
  },
  // 门店管理门店老板新增
  {
    path: "/store/boss/add",
    name: "storeBossAdd",
    component: () => import("@/views/store/boss/add"),
    meta: [1, 2],
  },
  // 门店管理门店老板新增
  {
    path: "/store/boss/update",
    name: "storeBossUpdate",
    component: () => import("@/views/store/boss/update"),
    meta: [1, 2],
  },
  // 门店管理门店老板详情
  {
    path: "/store/boss/detail",
    name: "storeBossDetail",
    component: () => import("@/views/store/boss/detail"),
    meta: [1, 2],
  },
  // 门店管理门店管理列表
  {
    path: "/store/manage",
    name: "storeManage",
    component: () => import("@/views/store/manage/index"),
    meta: [1, 2],
  },
  // 门店管理门店新增入驻
  {
    path: "/store/manage/addJoin",
    name: "storeManageaAddJoin",
    component: () => import("@/views/store/manage/addJoin"),
    meta: [1, 2],
  },
  // 门店管理门店修改入驻
  {
    path: "/store/manage/updateJoin",
    name: "storeManageaUpdateJoin",
    component: () => import("@/views/store/manage/updateJoin"),
    meta: [1, 2],
  },
  // 门店管理门店新增资料
  {
    path: "/store/manage/addAptitude",
    name: "storeManageaAddAptitude",
    component: () => import("@/views/store/manage/addAptitude"),
    meta: [1, 2],
  },
  // 门店管理门店修改资料
  {
    path: "/store/manage/updateAptitude",
    name: "storeManageaUpdateAptitude",
    component: () => import("@/views/store/manage/updateAptitude"),
    meta: [1, 2],
  },
  // 门店管理门店详情
  {
    path: "/store/manage/detail",
    name: "storeManageaDetail",
    component: () => import("@/views/store/manage/detail"),
    meta: [1, 2],
  },
  // // 项目管理项目审核列表
  // {
  //   path: "/project/audit",
  //   name: "projectAudit",
  //   component: () => import("@/views/project/audit"),
  // },
  // // 项目管理项目套餐审核详情
  // {
  //   path: "/project/audit/detail",
  //   name: "projectAuditDetail",
  //   component: () => import("@/views/project/audit/detail"),
  // },
  // 项目管理项目套餐列表
  {
    path: "/project/list",
    name: "projectList",
    component: () => import("@/views/project/list"),
  },
  // 项目管理项目套餐详情
  {
    path: "/project/list/detail",
    name: "projectListDetail",
    component: () => import("@/views/project/list/detail"),
  },
  // 优惠券
  {
    path: "/coupon/list",
    name: "couponList",
    component: () => import("@/views/coupon/list"),
  },
  // 资金管理退款申请
  {
    path: "/fund/refund",
    name: "fundRefund",
    component: () => import("@/views/fund/refund"),
  },
  // 资金管理钱包明细
  {
    path: "/fund/refund/wallet",
    name: "fundRefundWallet",
    component: () => import("@/views/fund/refund/wallet"),
  },
  // 资金管理提现申请
  {
    path: "/fund/withdraw",
    name: "fundWithdraw",
    component: () => import("@/views/fund/withdraw"),
  },
  // 资金管理账户明细
  {
    path: "/fund/withdraw/account",
    name: "fundWithdrawAccount",
    component: () => import("@/views/fund/withdraw/account"),
  },
  // 订单管理服务明细
  {
    path: "/order/serve",
    name: "orderServe",
    component: () => import("@/views/order/serve"),
  },
  // 订单管理服务明细服务详情
  {
    path: "/order/serve/detail",
    name: "orderServeDetail",
    component: () => import("@/views/order/serve/detail"),
  },
  // 官网管理
  {
    path: "/website/apply",
    name: "websiteApply",
    component: () => import("@/views/website/apply"),
  },
  // 黑名单
  {
    path: "/website/apply/blacklist",
    name: "websiteApplyBlacklist",
    component: () => import("@/views/website/apply/blacklist"),
  },
  // 新闻管理
  {
    path: "/website/news",
    name: "websiteNews",
    component: () => import("@/views/website/news"),
  },
  // 新闻新增
  {
    path: "/website/news/add",
    name: "websiteNewsAdd",
    component: () => import("@/views/website/news/add"),
  },
  // 新闻修改
  {
    path: "/website/news/update",
    name: "websiteNewsUpdate",
    component: () => import("@/views/website/news/update"),
  },
  // 新闻类型
  {
    path: "/website/news/type",
    name: "websiteNewsType",
    component: () => import("@/views/website/news/type"),
  },

  //菜单管理
  {
    path: "/system/menu",
    name: "systemMenu",
    component: () => import("@/views/system/menu/index"),
  },
  //菜单新增
  {
    path: "/system/menu/add",
    name: "systemMenuAdd",
    component: () => import("@/views/system/menu/add"),
  },
  //菜单修改
  {
    path: "/system/menu/update",
    name: "systemMenuUpdate",
    component: () => import("@/views/system/menu/update"),
  },
  //菜单详情
  {
    path: "/system/menu/detail",
    name: "systemMenuDetail",
    component: () => import("@/views/system/menu/detail"),
  },
  //角色管理
  {
    path: "/system/role",
    name: "systemRole",
    component: () => import("@/views/system/role/index"),
  },
  //角色新增
  {
    path: "/system/role/add",
    name: "systemRoleAdd",
    component: () => import("@/views/system/role/add"),
  },
  //角色修改
  {
    path: "/system/role/update",
    name: "systemRoleUpdate",
    component: () => import("@/views/system/role/update"),
  },
  //角色详情
  {
    path: "/system/role/detail",
    name: "systemRoleDetail",
    component: () => import("@/views/system/role/detail"),
  },
  //账号管理
  {
    path: "/system/account",
    name: "systemAccount",
    component: () => import("@/views/system/account/index"),
  },
  //账号新增
  {
    path: "/system/account/add",
    name: "systemAccountAdd",
    component: () => import("@/views/system/account/add"),
  },
  //账号修改
  {
    path: "/system/account/update",
    name: "systemAccountUpdate",
    component: () => import("@/views/system/account/update"),
  },
  //账号详情
  {
    path: "/system/account/detail",
    name: "systemAccountDetail",
    component: () => import("@/views/system/account/detail"),
  },
  {
    //列表页面 temp
    path: "/temp/list",
    name: "tempList",
    component: () => import("@/views/temp/index"),
  },
  {
    //新增页面 temp
    path: "/temp/add",
    name: "tempAdd",
    component: () => import("@/views/temp/add"),
  },
  {
    //修改页面 temp
    path: "/temp/update",
    name: "tempUpdate",
    component: () => import("@/views/temp/update"),
  },
  {
    //详情页面 temp
    path: "/temp/detail",
    name: "tempDetail",
    component: () => import("@/views/temp/detail"),
  },
];

const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: "/",
    routes: constantRoutes,
  });

const router = createRouter();

// 提示路由
export function hintRouter() {
  router.addRoute("dashboard", {
    path: "*",
    name: "401",
    component: () => import("@/views/401"),
  });
  // router.addRoute("dashboard", {
  //   path: "*",
  //   name: "404",
  //   component: () => import("@/views/404"),
  // });
}

// 重置路由
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
