/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * 页面路径验证
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 手机号验证
 * @param {string} str
 * @returns {Boolean}
 */
export function validUserPhone(str) {
  const reg = /^1[3456789]\d{9}$/
  return reg.test(str)
}

/**
 * 用户名验证
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}
